<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- 註冊表單 -->
      <b-card class="mb-0">

        <!-- 標題 -->
        <b-link class="brand-logo">
          <vuexy-logo />
          <h2 class="brand-text text-primary">
            註冊
          </h2>
        </b-link>

        <!-- 表單 -->
        <validation-observer
          ref="registerForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent
          >

            <!-- 信箱 -->
            <b-form-group
              label="信箱"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="信箱"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="regEmail"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="請輸入信箱"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- 使用者姓名 -->
            <b-form-group
              label="使用者姓名"
              label-for="username"
            >
              <validation-provider
                #default="{ errors }"
                name="使用者姓名"
                rules="required"
              >
                <b-form-input
                  id="username"
                  v-model="username"
                  :state="errors.length > 0 ? false:null"
                  name="register-username"
                  placeholder="請輸入使用者姓名"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- 密碼 -->
            <b-form-group
              label="密碼"
              label-for="register-password"
            >
              <validation-provider
                #default="{ errors }"
                rules="required|password"
                name="password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-password"
                    v-model="registerPassword"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="請輸入使用者密碼"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePassword"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- 重複密碼 -->
            <b-form-group
              label="重複密碼"
              label-for="register-password-retype"
            >
              <validation-provider
                #default="{ errors }"
                name="重複密碼"
                rules="required|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="register-password-retype"
                    v-model="registerPasswordRetype"
                    :type="passwordFieldTypeRetype"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="請輸入使用者密碼"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconRetype"
                      class="cursor-pointer"
                      @click="togglePasswordRetype"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="status"
                name="checkbox-1"
              >
                我同意
                <b-link v-b-modal.modal-tall>
                  隱私政策 & 條款
                </b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- 隱私政策條款MODAL:別打開，你會怕 -->
            <b-modal
              id="modal-tall"
              scrollable
              hide-footer
              title="隱私政策及條款"
            >
              <p :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                非常歡迎您光臨「曠世國際有限公司」（以下簡稱本公司），為了讓您能夠安心使用本公司的各項服務與資訊，特此向您說明本公司的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
              </p>
              <h4 style="font-weight:bold">
                一、隱私權保護政策的適用範圍
              </h4>
              <p :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                隱私權保護政策內容，包括本公司如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本公司以外的相關連結網站，也不適用於非本公司所委託或參與管理的人員。
              </p>
              <h4 style="font-weight:bold">
                二、個人資料的蒐集、處理及利用方式
              </h4>
              <ul :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                <li>當您造訪本公司或使用本公司所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。</li>
                <li>本公司在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。</li>
                <li>於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。</li>
                <li>為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。</li>
              </ul>
              <h4 style="font-weight:bold">
                三、資料之保護
              </h4>
              <ul :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                <li>本公司主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。</li>
                <li>如因業務需要有必要委託其他單位提供服務時，本公司亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。</li>
              </ul>
              <h4 style="font-weight:bold">
                四、網站對外的相關連結
              </h4>
              <p :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                本公司的網頁提供其他網站的網路連結，您也可經由本公司所提供的連結，點選進入其他網站。但該連結網站不適用本公司的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
              </p>
              <h4 style="font-weight:bold">
                五、與第三人共用個人資料之政策
              </h4>
              <p :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                本公司絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。前項但書之情形包括不限於：
              </p>
              <ul :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                <li>經由您書面同意。</li>
                <li>法律明文規定。</li>
                <li>為免除您生命、身體、自由或財產上之危險。</li>
                <li>與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集著依其揭露方式無從識別特定之當事人。</li>
                <li>當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。</li>
                <li>有利於您的權益。</li>
                <li>本公司委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。</li>
              </ul>
              <h4 style="font-weight:bold">
                六、Cookie之使用
              </h4>
              <p :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                為了提供您最佳的服務，本公司會在您的電腦中放置並取用我們的Cookie，若您不願接受Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie的寫入，但可能會導至網站某些功能無法正常執行 。
              </p>
              <h4 style="font-weight:bold">
                七、隱私權保護政策之修正
              </h4>
              <p :style="{color: `${!isDark ? '#444b60' : '#e4e4e4'}`}">
                本公司隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
              </p>
            </b-modal>

            <!-- 註冊按鈕 -->
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
              @click="onSubmitRegister"
            >
              註冊
            </b-button>
          </b-form>
        </validation-observer>

        <!-- 導向按鈕 -->
        <b-card-text class="text-center mt-2">
          <span>已經註冊過了? </span>
          <b-link :to="{name:'auth-login'}">
            <span>立即登入</span>
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text">
            or
          </div>
        </div>

        <!-- 社群按鈕(無功能) -->
        <div class="auth-footer-btn d-flex justify-content-center">
          <b-button
            variant="facebook"
            href="javascript:void(0)"
          >
            <feather-icon icon="FacebookIcon" />
          </b-button>
          <b-button
            variant="twitter"
            href="javascript:void(0)"
          >
            <feather-icon icon="TwitterIcon" />
          </b-button>
          <b-button
            variant="google"
            href="javascript:void(0)"
          >
            <feather-icon icon="MailIcon" />
          </b-button>
          <b-button
            variant="github"
            href="javascript:void(0)"
          >
            <feather-icon icon="GithubIcon" />
          </b-button>
        </div>

      </b-card>
    </div>

    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      sitekey="6LeKGO0aAAAAAFoXqpakoMDb8JgP8lmjPa7xwx09"
      @verify="Register"
    />
  </div>

</template>

<script>
// API
import { computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import { required, email, password } from '@validations'
import useJwt from '@/auth/jwt/useJwt'

// UI
import {
  BCard, BLink, BCardText, BForm, BModal, BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'

// Components
import useAppConfig from '@core/app-config/useAppConfig'
import VueRecaptcha from 'vue-recaptcha'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,

    BCard,
    BLink,
    BModal,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,

    ValidationProvider,
    ValidationObserver,
    VueRecaptcha,
  },
  data() {
    return {
      regEmail: '',
      username: '',
      registerPassword: '',
      registerPasswordRetype: '',

      passwordFieldType: 'password',
      passwordFieldTypeRetype: 'password',
      status: '',

      required,
      email,
      password,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    // 顯示密碼/隱藏密碼
    togglePassword() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password'
    },

    // 顯示重複密碼/隱藏重複密碼
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },

    // 提醒彈窗
    Alert(state, message) {
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-right',
        props: {
          title: `${message}`,
          icon: state === 'success' ? 'EditIcon' : 'InfoIcon',
          variant: state === 'success' ? 'success' : 'danger',
        },
      })
    },
    // 觸發註冊
    onSubmitRegister() {
      this.$refs.recaptcha.execute()
    },

    // 註冊
    Register(recaptchaToken) {
      this.$refs.registerForm.validate().then(isFinish => {
        if (isFinish) {
          useJwt.register({
            acc: this.regEmail,
            pw: this.registerPassword,
            name: this.username,
            rc_token: recaptchaToken,
          })
            .then(response => {
              if (response.data.success) {
                this.$router.replace({ name: 'auth-login' })
                  .then(() => {
                    this.Alert('success', response.data.message)
                  })
              } else {
                this.Alert('error', response.data.message)
              }
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
  setup() {
    localize('tw')
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')

    return {
      isDark,
    }
  },
}
</script>

<style lang="scss" scope>
@import '@core/scss/vue/pages/page-auth.scss';
.auth-wrapper{
  min-height: 75vh;
}
</style>
